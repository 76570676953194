import React from "react"

function ContactForm() {
  return (
    <form
      name="contact"
      method="POST"
      action="https://formspree.io/info@connectedbits.com"
    >
      <input
        className="u-full-width"
        type="text"
        placeholder="Full name"
        name="name"
        id="name"
      />
      <input
        className="u-full-width"
        type="email"
        placeholder="Email address"
        id="email"
        name="_replyto"
      />
      <input
        className="u-full-width"
        type="tel"
        placeholder="Phone number"
        id="phone"
        name="phone"
      />
      <textarea
        className="u-full-width message"
        placeholder="Message"
        id="message"
        name="message"
      ></textarea>
      <input className="button-primary" type="submit" value="Submit" />
    </form>
  )
}

export default ContactForm
