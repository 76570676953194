import React from "react"
import dave from "../images/founder-1.png"
import eric from "../images/founder-2.png"
import styles from "./ContactAboutUs.module.css"

function AboutUs() {
  return (
    <div className={`container ${styles.about}`}>
      <div className="row">
        <div className={`six columns ${styles.bio}`}>
          <h2>About us</h2>
          <p>
            Connected Bits is a Boston-area software company that develops
            mobile applications and the network services that power them.
          </p>
          <p>
            Dave Mitchell and Eric Carlson met while working at a startup and
            later worked together as part of Microsoft's MIT research alliance.
            Together they decided to take their extensive knowledge and
            expertise in development, design, and leadership to found Connected
            Bits in 2003.
          </p>
          <p>
            Since Connected Bits founding, they have collaborated with dozens of
            major cities across the United States and Canada to optimize worker
            productivity, to enable the public to quickly report issues, and to
            improve the relationship between the public and their local
            services.
          </p>
        </div>
        <div className={`six columns ${styles.bio}`}>
          <h2>Founded by</h2>
          <div className="row">
            <div className="four columns">
              <img src={dave} alt="Dave Mitchell" />
            </div>
            <div className="eight columns">
              <h3>Dave Mitchell</h3>
              <p>
                ran Microsoft's $25 million MIT research alliance and before
                that held engineering roles in six early-stage software startups
                two of which he co-founded.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="four columns">
              <img src={eric} alt="Eric Carlson" />
            </div>
            <div className="eight columns">
              <h3>Eric Carlson</h3>
              <p>
                was the CTO for Kana and Silknet Software, both public
                companies. Eric's design for the Silknet eBusiness platform,
                helped fuel Silknet's rapid growth and eventual $4.2 billion
                acquisition by Kana.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
