import React from "react"

function HeroContent({ copy, image }) {
  return (
    <div className="row hero-content">
      {copy && <div className="hero-copy">{copy}</div>}
      {image && <div className="hero-image">{image}</div>}
    </div>
  )
}

export default HeroContent
