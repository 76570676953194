import React from "react"
import icon from "../images/spot-icon.svg"

function ContactAddress() {
  return (
    <p class="address">
      <img src={icon} alt="Spot Mobile" />
      One Hardy Road
      <br />
      Suite 208
      <br />
      Bedford, NH 03110
      <br />
      603.889.2200
    </p>
  )
}

export default ContactAddress
