import React from "react"

function Hero({ children }) {
  return (
    <div className="hero">
      <div className="container">
        <div className="row hero-content">{children}</div>
      </div>
    </div>
  )
}

export default Hero
