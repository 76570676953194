import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import HeroContent from "../components/HeroContent"
import ContactAboutUs from "../components/ContactAboutUs"
import ContactAddress from "../components/ContactAddress"
import ContactForm from "../components/ContactForm"

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Hero className="contact">
        <HeroContent
          copy={
            <div>
              <h4>Contact Us</h4>
              <p>Want to learn more? Get in touch!</p>
              <ContactAddress />
            </div>
          }
          image={
            <div>
              <ContactForm />
            </div>
          }
        />
      </Hero>
      <ContactAboutUs />
    </Layout>
  )
}

export default ContactPage
